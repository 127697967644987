.card-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    

}
.fs-7{
    font-size: 12px;
}
.scroll{
    overflow-y: scroll !important;
    height: 100vh !important;
}

.subscription-card{
    padding: 1rem 1rem;
    border-radius: 0.6rem;
    background: linear-gradient(90deg,#3182ce,#887bdc);
    box-shadow: 0 3px 17px 0 rgba(26,13,62,.071);
    color: white;
    width: 100%;
}

/* swiper code start */
/* swiper code start */
.swiper-container {
    position: relative;
  }
  
  .pagination,
  .navigation-buttons {
      position: absolute;
      bottom: 20px; 
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      align-items: center;
    }
    
    .navigation-buttons button {
        margin: 0 10px;
        border: none;
        background: none;
    }
    /* swiper code End */
    



    