@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap");
@import url(https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/css/bootstrap.min.css);

body {
    background-color: #e0e3e8;
    font-family: "Rubik", sans-serif;
}

a,
a:hover,
a:focus {
    text-decoration: none;
    transition: 0.5s;
    outline: none;
}

/* -----Search-filter */
.transaction-card {
    background-color: #fffef7;
    border-radius: 10px;
    border: 1px dashed gray;
    margin: 10px;
}

.form-control {
    display: block;
    width: 100%;
    height: auto;
    padding: 15px 20px;
    font-size: 14px;
    line-height: 1.4;
    color: #475f7b;
    background-color: #fff;
    border: 1px solid #dfe3e7;
    border-radius: 0.267rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-control:focus {
    color: #475f7b;
    background-color: #fff;
    border-color: #5a8dee;
    outline: 0;
    box-shadow: 0 3px 8px 0 rgb(0 0 0 / 10%);
}

.card-header {
    background-color: #fff !important;
}

.card {
    /* box-shadow: 0px 5px 10px rgba(90, 116, 148, 0.3); */
    background-color: #fff;
    border-radius: 1px !important;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0.3px;
    color: #475f7b;
    overflow: hidden;
    /* border: none !important; */
}

.table-filter-info {
    padding: 15px;
}

tbody {
    height: 20vh;
}

tr {
    height: 10vh;
    margin: auto 0;
}

a {
    text-decoration: none !important;
}

.thead-primary tr th {
    color: #000;

}

.table td,
.table th {
    padding: 0.75rem;
    vertical-align: middle;
    border-top: 1px solid #dee2e6;
}

.thead-primary {
    background-color: #f9fafb;
}

.dt-pagination-ul {
    display: flex;
    align-items: center;
    margin-bottom: 0;
    padding-left: 0;
    border-top: 1px solid #dee2e6;
    padding-top: 15px;
}

.dt-pagination .dt-item {
    display: inline-block;
}

.dt-pagination .dt-item .dt-link {
    padding: 6px 8px;
    min-width: 32px;
    min-height: 32px;
    border-radius: 8px;
    margin-left: 0;
    margin-right: 8px;
    border: 1px solid #fff;
    font-size: 12px;
    font-weight: 500;
    color: #8A93A6;
    display: inline-block;
    text-align: center;
    cursor: pointer;
}

.dt-pagination .dt-item .dt-link:hover {
    background-color: #ebf2fd;
    color: #5a8dee;
}

.dt-pagination .dt-item.disabled .dt-link {
    background-color: #F6F7FB;
    color: #D7DAE0;
    cursor: not-allowed;
}

.dt-pagination .dt-item:first-child .dt-link,
.dt-pagination .dt-item:last-child .dt-link {
    background-color: #F6F7FB;
}

.dt-pagination .dt-item.active .dt-link {
    background-color: #5a8dee;
    border-color: #5a8dee;
    color: #fff !important;
}

.dt-pagination {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}

.react-datepicker-wrapper {
    display: block;
    width: 100% !important;
}